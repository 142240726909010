import { accessAtLeastManager } from 'charts/shared/access';
import { useGetChart } from 'charts/shared/hooks';
import { useContext, useEffect, useState } from 'react';
import {
  ApexAxisChartAttributes,
  ChartType,
  DateRangeIntervalWithoutWeek,
  InspectionChart,
} from 'shared/types/analytics';
import { ChartDetailsContext } from 'views/analytics/chartDetails/ChartDetailsProvider';
import { SimpleChartContext } from 'views/analytics/types';
import { useInspectionEvolutionFilters } from './filters';
import {
  detailedViewInspectionEvolutionChartOptions,
  listViewInspectionEvolutionChartOptions,
} from './options';
import { TypedChartState } from 'charts/shared/types';
import { IntlShape, useIntl } from 'react-intl';
import { isoToProjectTimezonedDate } from 'shared/utils/date/dates';
import { projectDataSelector } from 'redux/selectors/project';
import { useSelector } from 'react-redux';

export function useInspectionEvolution(): SimpleChartContext<any> {
  const intl = useIntl();
  const { timezone } = useSelector(projectDataSelector);
  const filters = useInspectionEvolutionFilters();

  const isDetailedView = useContext(ChartDetailsContext);
  const options = isDetailedView
    ? detailedViewInspectionEvolutionChartOptions
    : listViewInspectionEvolutionChartOptions;
  const [state, setState] = useState<
    TypedChartState<[ApexAxisChartAttributes, ApexAxisChartAttributes]>
  >({
    series: [{ data: [], labels: [] }, { data: [] }],
    options,
  });
  const { series, loading, load, reload, updatedAt } = useGetChart(
    InspectionChart.inspectionEvolution,
    filters,
    undefined,
    state.series
  );

  const isLoading = !filters || loading;

  useEffect(() => {
    if (!filters) return;
    setState((prev) => {
      return {
        ...prev,
        series: series,
        options: {
          ...options,
          labels: series[0].labels,
          xaxis: {
            categories: createXAxisCategories(
              series[0].labels,
              filters.dataScope.filters[0].value,
              intl,
              timezone
            ),
          },
        },
      };
    });
  }, [series, filters, options, timezone]);

  return {
    state,
    filters,
    titleId: 'inspection_evolution_chart',
    chartType: ChartType.line,
    availableTypes: [ChartType.line],
    canAccess: accessAtLeastManager,
    loading: isLoading,
    load: load,
    forceUpdate: reload,
    updatedAt: (): number => updatedAt,
  };
}

function createXAxisCategories(
  res: string[] | undefined,
  interval: DateRangeIntervalWithoutWeek,
  intlContext: IntlShape,
  timezone: string
): string[] {
  if (!res) return [];
  return res.map((r) =>
    isoToProjectTimezonedDate(r, timezone).toLocaleString(
      categoriesFormatByInterval[interval],
      {
        locale: intlContext.locale,
      }
    )
  );
}

const categoriesFormatByInterval = {
  [DateRangeIntervalWithoutWeek.day]: {
    day: '2-digit' as '2-digit',
    month: 'short' as 'short',
    year: 'numeric' as 'numeric',
  },
  [DateRangeIntervalWithoutWeek.month]: {
    month: 'short' as 'short',
    year: 'numeric' as 'numeric',
  },
  [DateRangeIntervalWithoutWeek.year]: {
    year: 'numeric' as 'numeric',
  },
};
