import { Dependencies } from 'charts/shared/types';
import {
  FailedInspectionFactorDependencies,
  InitializeDependencyAction,
} from '../types';
import { FailedInspectionFactorFilters } from 'shared/types/analytics';

export function dependenciesReducer(
  state: Dependencies<
    FailedInspectionFactorDependencies,
    FailedInspectionFactorFilters
  >,
  action: InitializeDependencyAction
): Dependencies<
  FailedInspectionFactorDependencies,
  FailedInspectionFactorFilters
> {
  switch (action.type) {
    case 'SET_FILTERS_RESPONSE':
      return {
        ...state,
        filters: action.payload,
      };

    case 'SET_PROCESSES':
      return {
        ...state,
        processes: action.payload,
      };

    case 'SET_SITES':
      return {
        ...state,
        sites: action.payload,
      };

    case 'SET_MANAGERS':
      return {
        ...state,
        managers: action.payload,
      };

    case 'SET_PROJECT_ID':
      return {
        ...state,
        projectId: action.payload,
      };

    case 'SET_TIMEZONE':
      return {
        ...state,
        timezone: action.payload,
      };
    default: {
      // eslint-disable-next-line no-console
      console.error('Unknown ACTION in dependenciesReducer');
      return state;
    }
  }
}
