import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import {
  ApexSeriesAndLabels,
  CompletedInspectionsByTemplateFilters,
  DateRangeObject,
} from 'shared/types/analytics';
import {
  filterByCreatedBy,
  filterByDateRange,
  filterByProcess,
  filterInspectionBySite,
  increaseEntitySeries,
  toSortedApexSeries,
} from '../seriesCreation';
import { InspectionTemplateEntity } from 'serviceWorker/repository/inspectionTemplate/entity';
import { LabelledEntity } from 'shared/types/commonView';

export async function createSeriesAndLabelsFromInspectionsWithFilters(
  inspections: InspectionEntity[],
  templateProcessByIdRecord: Record<string, { process: string }>,
  filters: CompletedInspectionsByTemplateFilters,
  timezone: string,
  additionalParams: { templates: InspectionTemplateEntity[] }
): Promise<ApexSeriesAndLabels> {
  const [dateRangeFilter, processFilter, siteFilter, createdByFilter] =
    filters.dataScope.filters;

  const templatesLabels = {};
  const templatesMap = additionalParams.templates.reduce(
    (map, template) => {
      map.set(template._id, {
        _id: template._id,
        label:
          template.summary.length > 15
            ? template.summary.substring(0, 15) + `…`
            : template.summary,
      });
      return map;
    },
    new Map<string, LabelledEntity>()
  );
  const result: { [key: string]: number } = {};

  inspections.forEach((inspection) => {
    if (inspection.isCompleted !== true) {
      return false;
    }

    const passedDateRange = filterByDateRange(
      dateRangeFilter.value as DateRangeObject,
      inspection.inspectionDate || inspection.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return false;
    }

    if (!templateProcessByIdRecord[inspection.template]) {
      throw new Error(
        `Template _id:${inspection.template}; missing for inspection _id:${inspection._id}`
      );
    }
    const passedProcess = filterByProcess(
      processFilter.value as string[],
      templateProcessByIdRecord[inspection.template]
    );
    if (!passedProcess) {
      return false;
    }

    const passedSite = filterInspectionBySite(
      siteFilter.value as string[],
      inspection
    );
    if (!passedSite) {
      return false;
    }

    const passedInspector = filterByCreatedBy(
      createdByFilter.value as string[],
      inspection
    );
    if (!passedInspector) {
      return false;
    }

    if (!templatesMap.get(inspection.template)) {
      throw new Error(
        `Template _id:${inspection.template}; missing for inspection _id:${inspection._id}`
      );
    }
    increaseEntitySeries(
      result,
      templatesLabels,
      templatesMap.get(inspection.template)!
    );
  });

  return toSortedApexSeries(result, templatesLabels);
}
