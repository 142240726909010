import { InspectionModel } from 'shared/domain/inspection/inspectionModel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { inspectionEntityToModel } from 'serviceWorker/repository/inspection/inspectionEntityToModel';
import { ChannelNames } from 'shared/domain/channelNames';
import * as inspections from 'serviceWorker/db/inspections';
import * as inspectionsService from 'serviceWorker/db/inspectionsService';
import { awaitEntities } from '../factories/awaitEntities';
import { waitForMessage } from '../factories/waitForMessage';
import { getUsersSynchronized } from '../users/getUsers';
import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';

const waitForInspections = waitForMessage(
  ChannelNames.inspectionChannel,
  DomainMessagesTypes.allInspections
);

export async function getInspectionById(
  id: string
): Promise<InspectionModel> {
  await awaitEntities(inspectionsService, waitForInspections);
  const [inspectionEntity, users] = await Promise.all([
    inspections.getOne(id),
    getUsersSynchronized(),
  ]);
  if (!inspectionEntity) {
    throw new Error(`Cannot find inspection: ${id}`);
  }
  return inspectionEntityToModel(inspectionEntity, users);
}

export async function getInspections(): Promise<InspectionEntity[]> {
  await awaitEntities(inspectionsService, waitForInspections);
  const result = await inspections.get();
  if (!result) {
    throw new Error(`Cannot find inspections`);
  }
  return result;
}
