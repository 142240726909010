import { useAtLeastManagers } from 'charts/shared/hooks';
import { useSites } from 'components/dataProviders/withSites';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import {
  FailedInspectionFactorFilters,
  InspectionChart,
} from 'shared/types/analytics';
import { filtersReducer } from './reducers/filtersReducer';
import { useFiltersActions } from './hooks/filtersActions';
import { useDependencies } from './hooks/useDependencies';
import { createFiltersForView } from './createFilters';
import { useMountedRef } from 'hooks/useMountRef';
import { useFiltersPersistence } from '../shared/useFiltersPersistence';
import { defaultValues } from './defaultFilters';

export function useFailedInspectionFactorFilters():
  | FailedInspectionFactorFilters
  | undefined {
  const mountedRef = useMountedRef();
  const managers = useAtLeastManagers();
  const {
    _id: projectId,
    timezone,
    processes: userProcesses,
  } = useSelector(projectDataSelector);

  const {
    sites: { items: sites },
  } = useSites();

  const [filters, dispatchFilters] = useReducer(filtersReducer, undefined);
  const { rewriteFilters, ...filtersActions } =
    useFiltersActions(dispatchFilters);

  const { savedFilters } = useFiltersPersistence(
    `${projectId}_${InspectionChart.failedInspectionFactor}`,
    filters,
    projectId,
    defaultValues
  );

  const { dependencies, isDependenciesDefined } = useDependencies(
    managers,
    userProcesses,
    sites,
    projectId,
    timezone,
    savedFilters
  );

  useEffect(() => {
    if (!mountedRef.current || !userProcesses.length) return;

    if (isDependenciesDefined(dependencies)) {
      rewriteFilters(createFiltersForView(dependencies, filtersActions));
    }
  }, [
    dependencies,
    userProcesses.length,
    isDependenciesDefined,
    rewriteFilters,
  ]);

  return filters;
}
