import {
  DateRangeIntervalWithoutWeek,
  InspectionEvolutionFilters,
} from 'shared/types/analytics';
import {
  isDateDifferenceInvalid,
  setMultiselectFilterValue,
} from 'charts/shared/filters';
import { FilterAction } from '../types';

export function filtersReducer(
  state: InspectionEvolutionFilters | undefined,
  action: FilterAction
): InspectionEvolutionFilters | undefined {
  if (action.type === 'REWRITE_FILTERS') return action.payload;
  if (!state) return undefined;

  switch (action.type) {
    case 'SET_DATE_INTERVAL': {
      const { from, to } = state.dataScope.filters[1].value;

      if (
        isDateDifferenceInvalid(
          from,
          to,
          action.payload.interval,
          action.payload.timezone
        )
      ) {
        action.payload.showErrorDialog();
        return state;
      }

      const [first, ...rest] = state.dataScope.filters;
      return {
        ...state,
        dataScope: {
          ...state.dataScope,
          filters: [{ ...first, value: action.payload.interval }, ...rest],
        },
      };
    }

    case 'SET_DATE_RANGE': {
      const { from, to, timezone } = action.payload;
      const interval: DateRangeIntervalWithoutWeek =
        state.dataScope.filters[0].value;
      if (isDateDifferenceInvalid(from, to, interval, timezone)) {
        action.payload.showErrorDialog();
        return state;
      }

      const [first, second, ...rest] = state.dataScope.filters;
      return {
        ...state,
        dataScope: {
          ...state.dataScope,
          filters: [
            first,
            {
              ...second,
              value: {
                from: action.payload.from,
                to: action.payload.to,
              },
            },
            ...rest,
          ],
        },
      };
    }

    case 'SET_PROCESSES':
      return setMultiselectFilterValue(state, 2, action.payload);

    case 'SET_SITES':
      return setMultiselectFilterValue(state, 3, action.payload);

    case 'SET_MANAGERS':
      return setMultiselectFilterValue(state, 4, action.payload);
    default:
      const exhaustiveCheck: never = action;
      throw new Error(
        `Unhandled filters reducer case: ${exhaustiveCheck['type']}`
      );
  }
}
