import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import {
  ApexAxisChartSeries,
  DateRangeIntervalWithoutWeek,
  DateRangeObject,
  InspectionEvolutionFilters,
} from 'shared/types/analytics';
import {
  filterByCreatedBy,
  filterByDateRange,
  filterByProcess,
  filterInspectionBySite,
} from '../seriesCreation';
import { DateTime } from 'luxon';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';
import { isoToProjectTimezonedDate } from 'shared/utils/date/dates';
import { DayMomentValidation } from 'shared/types/time';

export async function createSeriesAndLabelsFromInspectionsWithFilters(
  inspections: InspectionEntity[],
  templateProcessByIdRecord: Record<string, { process: string }>,
  filters: InspectionEvolutionFilters,
  timezone: string
): Promise<ApexAxisChartSeries> {
  const [
    dateInterval,
    dateRangeFilter,
    processFilter,
    siteFilter,
    createdByFilter,
  ] = filters.dataScope.filters;

  const amountGrouped = createDateRangeSplitByUnit(
    (dateRangeFilter.value as DateRangeObject).from,
    (dateRangeFilter.value as DateRangeObject).to,
    dateInterval.value as DateRangeIntervalWithoutWeek,
    timezone
  );
  const averageInspectionResultGrouped = createDateRangeSplitByUnit(
    (dateRangeFilter.value as DateRangeObject).from,
    (dateRangeFilter.value as DateRangeObject).to,
    dateInterval.value as DateRangeIntervalWithoutWeek,
    timezone
  );

  inspections.forEach((inspection) => {
    const passedDateRange = filterByDateRange(
      dateRangeFilter.value as DateRangeObject,
      inspection.inspectionDate || inspection.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return false;
    }

    if (!templateProcessByIdRecord[inspection.template]) {
      throw new Error(
        `Template _id:${inspection.template}; missing for inspection _id:${inspection._id}`
      );
    }
    const passedProcess = filterByProcess(
      processFilter.value,
      templateProcessByIdRecord[inspection.template]
    );
    if (!passedProcess) {
      return false;
    }

    const passedSite = filterInspectionBySite(
      siteFilter.value,
      inspection
    );
    if (!passedSite) {
      return false;
    }

    const passedInspector = filterByCreatedBy(
      createdByFilter.value,
      inspection
    );
    if (!passedInspector) {
      return false;
    }

    if (!inspection.isCompleted) {
      return false;
    }

    const group = createGroup(
      inspection,
      dateInterval.value as DateRangeIntervalWithoutWeek
    );

    addToGroup(
      amountGrouped,
      averageInspectionResultGrouped,
      group,
      inspection
    );
  });
  const sortedDates = Object.keys(amountGrouped).sort();
  const sortedAmount = sortedDates.map(
    (groupValue) => amountGrouped[groupValue]
  );

  const sortedAverage = sortedDates.map((groupValue) => {
    const passed =
      averageInspectionResultGrouped[groupValue]?.[
        ComplianceCheckResult.passed
      ] || 0;
    const notApplicable =
      averageInspectionResultGrouped[groupValue]?.[
        ComplianceCheckResult.notApplicable
      ] || 0;
    const result = (passed / (passed + notApplicable || 1)) * 100;
    return Math.round((result + Number.EPSILON) * 100) / 100;
  });

  return [
    {
      name: 'Completed',
      type: 'column',
      data: sortedAmount,
      labels: sortedDates,
    },
    {
      name: 'Score',
      type: 'line',
      data: sortedAverage,
    },
  ];
}

type AverageInspectionResult = {
  [ComplianceCheckResult.passed]: number;
  [ComplianceCheckResult.notApplicable]: number;
};
function addToGroup(
  amountGrouped: { [key: string]: number },
  averageInspectionResultGrouped: {
    [key: string]: AverageInspectionResult | 0;
  },
  group: string,
  inspection: InspectionEntity
): void {
  if (!amountGrouped[group]) {
    amountGrouped![group] = 0;
  }
  amountGrouped![group] += 1;

  if (!averageInspectionResultGrouped[group]) {
    averageInspectionResultGrouped[group] = {
      [ComplianceCheckResult.passed]: 0,
      [ComplianceCheckResult.notApplicable]: 0,
    };
  }

  inspection.protocol.forEach((protocolElement) => {
    if (
      protocolElement.complianceCheck.result ===
        ComplianceCheckResult.unset ||
      protocolElement.complianceCheck.result ===
        ComplianceCheckResult.notPassed
    ) {
      return;
    }
    averageInspectionResultGrouped[group]![
      protocolElement.complianceCheck.result
    ] += 1;
  });
}

function createGroup(
  inspection: InspectionEntity,
  unit: DateRangeIntervalWithoutWeek
): string {
  return DateTime.fromISO(inspection.createdAt).toFormat(
    DateFormatFromUnit[unit]
  );
}

function createDateRangeSplitByUnit(
  startDate: string,
  endDate: string,
  unit: DateRangeIntervalWithoutWeek,
  projectTimezone: string
): { [key: string]: 0 } {
  const dateRange = {};

  let dateStart = isoToProjectTimezonedDate(
    startDate,
    projectTimezone,
    DayMomentValidation.start
  );
  const dateEnd = isoToProjectTimezonedDate(
    endDate,
    projectTimezone,
    DayMomentValidation.end
  );
  let iteration = 0;
  let dateFormattedByUnit;
  while (dateEnd.diff(dateStart, unit).get(unit) >= 0) {
    dateFormattedByUnit = dateStart.toFormat(DateFormatFromUnit[unit]);
    dateRange[dateFormattedByUnit] = 0;
    dateStart = dateStart.plus({ [`${unit}`]: 1 });
    if (iteration === 0) {
      dateStart = dateStart.startOf(unit);
    }
    iteration += 1;
  }
  return dateRange;
}

export function onDayStart(date: DateTime): DateTime {
  return date.startOf('day');
}

export function onDayEnd(date: DateTime): DateTime {
  return date.endOf('day');
}

export function toDayStartInTimezone(
  date: Date,
  projectTimezone: string
): Date {
  return DateTime.fromJSDate(date, { zone: projectTimezone })
    .startOf('day')
    .toJSDate();
}

export function toDayEndInTimezone(
  date: Date,
  projectTimezone: string
): Date {
  return DateTime.fromJSDate(date, { zone: projectTimezone })
    .endOf('day')
    .toJSDate();
}

export enum DateFormatFromUnit {
  'day' = 'yyyy-MM-dd',
  'month' = 'yyyy-MM',
  'year' = 'yyyy',
}
