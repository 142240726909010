import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { HashMap } from 'shared/types/commonView';

export type ApexSeriesAndLabels = [number[], string[]];
export enum IssueChart {
  originator = 'originator',
  rootCause = 'rootCause',
  impact = 'impact',
  workType = 'workType',
  environmentalAspect = 'environmentalAspect',
  hsEffect = 'hsEffect',
  hsHazardType = 'hsHazardType',
}

export enum InspectionChart {
  completed = 'completed',
  averageInspectionResult = 'averageInspectionResult',
  failedInspectionFactor = 'failedInspectionFactor',
  completedInspectionsByTemplate = 'completedInspectionsByTemplate',
  inspectionEvolution = 'inspectionEvolution',
}

export type FilterValues = {
  dataScope: FilterValueGroup;
  chartSettings?: FilterValueGroup;
  presented?: FilterValueGroup;
};

type FilterValueGroup = {
  labelId: string;
  filters: FilterValue[];
};

export type FilterValue = {
  labelId: string;
  value: any;
};

export type EstimatedCost = {
  cost: number;
};

export type FinalCost = {
  outstanding: number;
  settled: number;
};

export type Finances = {
  estimatedCosts: HashMap<EstimatedCost>;
  finalCosts: HashMap<FinalCost>;
  expectedFines: HashMap<number>;
  imposedFines: HashMap<number>;
};

// TODO: now
export type EstimatedSingleCost = any;
export type FinalSingleCost = any;
export type CostsUnion = EstimatedSingleCost | FinalSingleCost;

export type CalculationFunction<T> = (current: T, cost: any) => T;

export type DateRangeObject = {
  from: string;
  to: string;
};
type OriginatorFiltersValuesUnion = string[] | DateRangeObject;

export type OriginatorFilters = {
  dataScope: ChartFilterGroup<OriginatorFiltersValuesUnion>;
};
export type AnyFilters<T = any, P = undefined, K = undefined> = {
  dataScope: ChartFilterGroup<T>;
  chartSettings?: ChartFilterGroup<P>;
  presented?: ChartFilterGroup<K>;
};

export type ChartFilterGroup<T> = {
  labelId: string;
  filters: ChartFilter<T>[];
};

export type ChartFilter<T> = {
  labelId: string;
  type: FilterTypes;
  available?: any[];
  labels?: HashMap<string>;
  value: T;
  setter: (...args: any) => void;
  toUrlQuery?: (value: any) => string;
  fieldRelation?: IssueFieldNames;
};
export type EnvironmentalAspectFilters = {
  dataScope: ChartFilterGroup<EnvironmentalAspectFiltersUnion>;
};
export type HsEffectFilters = {
  dataScope: ChartFilterGroup<HsEffectFiltersUnion>;
};
export type ImpactFilters = {
  dataScope: ChartFilterGroup<ImpactFiltersUnion>;
};
export type WorkTypeFilters = {
  dataScope: ChartFilterGroup<WorkTypeFiltersValuesUnion>;
};
export type RootCauseFilters = {
  dataScope: ChartFilterGroup<RootCauseFiltersValuesUnion>;
};
// taken directly from declaration file

export type ApexAxisChartSeries = ApexAxisChartAttributes[];
export type ApexAxisChartAttributes = {
  name?: string;
  type?: string;
  color?: string;
  labels?: string[];
  data:
    | (number | null)[]
    | {
        x: any;
        y: any;
        fillColor?: string;
        strokeColor?: string;
        meta?: any;
        goals?: any;
      }[]
    | [number, number | null][]
    | [number, (number | null)[]][];
};
export type CompletedInspectionsFilters = {
  dataScope: ChartFilterGroup<CompletedInspectionsFiltersUnion>;
};
export type AverageInspectionResultFilters = {
  dataScope: ChartFilterGroup<AverageInspectionResultFiltersUnion>;
};
export type FailedInspectionFactorFilters = {
  dataScope: ChartFilterGroup<FailedInspectionFactorFiltersUnion>;
};
export type CompletedInspectionsByTemplateFilters = {
  dataScope: ChartFilterGroup<CompletedInspectionsByTemplateFiltersUnion>;
};
export type InspectionEvolutionFilters = {
  dataScope: {
    labelId: string;
    filters: [
      ChartFilter<DateRangeIntervalWithoutWeek & { __value__?: never }>,
      ChartFilter<DateRangeObject & { __value__?: never }>,
      ChartFilter<string[]>,
      ChartFilter<string[]>,
      ChartFilter<string[]>,
    ];
  };
};
export type HazardTypeFilters = {
  dataScope: ChartFilterGroup<HazardTypeFiltersUnion>;
};
export type EnvironmentalAspectFiltersUnion = string[] | DateRangeObject;
export type HsEffectFiltersUnion = string[] | DateRangeObject;
export type HazardTypeFiltersUnion = string[] | DateRangeObject;
export type ImpactFiltersUnion =
  | string
  | string[]
  | DateRangeObject
  | HashMap<boolean>;
export type CompletedInspectionsFiltersUnion = string[] | DateRangeObject;
export type AverageInspectionResultFiltersUnion =
  | string[]
  | DateRangeObject;
export type FailedInspectionFactorFiltersUnion =
  | string[]
  | DateRangeObject;
export type CompletedInspectionsByTemplateFiltersUnion =
  | string[]
  | DateRangeObject;
export type InspectionEvolutionFiltersUnion =
  | string[]
  | DateRangeObject
  | DateRangeIntervalWithoutWeek;
export type ChartFiltersUnion =
  | DetectedResolvedFilters
  | RootCauseFilters
  | OriginatorFilters
  | ImpactFilters
  | EnvironmentalAspectFilters
  | WorkTypeFilters
  | HsEffectFilters
  | HazardTypeFilters;
export enum FilterTypes {
  radio = 'radio',
  checkbox = 'checkbox',
  segments = 'segments',
  multiselect = 'multiselect',
  daterange = 'daterange',
  select = 'select',
}
export type RootCauseFiltersValuesUnion =
  | string
  | string[]
  | DateRangeObject
  | HashMap<boolean>;
export type WorkTypeFiltersValuesUnion = string[] | DateRangeObject;
export type DetectedResolvedFilters = {
  chartSettings: ChartFilterGroup<ChartSettingsFilters>;
  dataScope: ChartFilterGroup<DataScopeFilters>;
  presented: ChartFilterGroup<PresentedFilters>;
};
export type ChartSettingsFilters = string | ChartType | HashMap<boolean>;
export type DataScopeFilters =
  | string
  | DateRangeInterval
  | string[]
  | DateRangeObject
  | HashMap<boolean>;
export type PresentedFilters = Record<PresentedTypes, boolean>;
export enum DateRangeInterval {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}
export enum DateRangeIntervalWithoutWeek {
  day = 'day',
  month = 'month',
  year = 'year',
}
export enum PresentedTypes {
  detected = 'detected',
  resolved = 'resolved',
}
export enum ChartType {
  bar = 'bar',
  area = 'area',
  line = 'line',
  donut = 'donut',
  radialBar = 'radialBar',
  number = 'number',
}
