import { DefinedDependencies } from 'charts/shared/types';
import {
  CompletedInspectionsByTemplateDependencies,
  FilterActionCalls,
} from './types';
import {
  CompletedInspectionsByTemplateFilters,
  FilterTypes,
} from 'shared/types/analytics';
import { sanitizeDateFilterValue } from 'charts/shared/model';
import { LabelledEntity } from 'shared/types/commonView';
import { createLabels } from 'charts/shared/filters';

export function createFiltersForView(
  {
    filters,
    managers,
    processes,
    sites,
    timezone,
  }: DefinedDependencies<
    CompletedInspectionsByTemplateDependencies,
    CompletedInspectionsByTemplateFilters
  >,
  {
    setDateRange,
    setManagers,
    setProcesses,
    setSites,
  }: Pick<
    FilterActionCalls,
    'setDateRange' | 'setProcesses' | 'setSites' | 'setManagers'
  >
): CompletedInspectionsByTemplateFilters {
  return {
    dataScope: {
      labelId: 'data_scope_tab',
      filters: [
        {
          labelId: 'data_scope_date_range',
          type: FilterTypes.daterange,
          value: {
            from: sanitizeDateFilterValue(
              filters.dataScope.filters[0].value.from,
              timezone
            ),
            to: sanitizeDateFilterValue(
              filters.dataScope.filters[0].value.to,
              timezone
            ),
          },
          setter: (value): void => {
            setDateRange(value);
          },
        },
        {
          labelId: 'general_process',
          type: FilterTypes.multiselect,
          available: processes.map((process: LabelledEntity) => {
            return { _id: process._id, label: process.label };
          }),
          labels: createLabels(processes),
          value: filters.dataScope.filters[1].value,
          setter: (value: string[]): void => {
            setProcesses(value);
          },
        },
        {
          labelId: 'data_scope_sites',
          type: FilterTypes.multiselect,
          available: sites,
          labels: createLabels(sites),
          value: filters.dataScope.filters[2].value,
          setter: (value: string[]): void => {
            setSites(value);
          },
        },
        {
          labelId: 'inspection_table_author',
          type: FilterTypes.multiselect,
          available: managers,
          labels: createLabels(managers),
          value: filters.dataScope.filters[3].value,
          setter: (value: string[]): void => {
            setManagers(value);
          },
        },
      ],
    },
  };
}
