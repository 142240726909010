import { Dependencies } from 'charts/shared/types';
import {
  CompletedInspectionsDependencies,
  InitializeDependencyAction,
} from '../types';
import { CompletedInspectionsFilters } from 'shared/types/analytics';

export function dependenciesReducer(
  state: Dependencies<
    CompletedInspectionsDependencies,
    CompletedInspectionsFilters
  >,
  action: InitializeDependencyAction
): Dependencies<
  CompletedInspectionsDependencies,
  CompletedInspectionsFilters
> {
  switch (action.type) {
    case 'SET_FILTERS_RESPONSE':
      return {
        ...state,
        filters: action.payload,
      };

    case 'SET_PROCESSES':
      return {
        ...state,
        processes: action.payload,
      };

    case 'SET_SITES':
      return {
        ...state,
        sites: action.payload,
      };

    case 'SET_MANAGERS':
      return {
        ...state,
        managers: action.payload,
      };

    case 'SET_PROJECT_ID':
      return {
        ...state,
        projectId: action.payload,
      };

    case 'SET_TIMEZONE':
      return {
        ...state,
        timezone: action.payload,
      };
    default:
      const exhaustiveCheck: never = action;
      throw new Error(
        `Unhandled dependencies reducer case: ${exhaustiveCheck['type']}`
      );
  }
}
