import { Dispatch, useCallback, useMemo } from 'react';
import { SiteModel } from 'shared/domain/site/types/model';
import { FilterValues } from 'shared/types/analytics';
import { LabelledEntity } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import {
  DependencyActionCalls,
  InitializeDependencyAction,
} from '../types';
import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';

export function useDependenciesActions(
  dispatch: Dispatch<InitializeDependencyAction>
): DependencyActionCalls {
  const setFiltersResponse = useCallback(
    (value: FilterValues) => {
      dispatch({ type: 'SET_FILTERS_RESPONSE', payload: value });
    },
    [dispatch]
  );

  const setProcesses = useCallback(
    (value: ProcessType[]) => {
      dispatch({ type: 'SET_PROCESSES', payload: value });
    },
    [dispatch]
  );

  const setSites = useCallback(
    (value: SiteModel[]) => {
      dispatch({ type: 'SET_SITES', payload: value });
    },
    [dispatch]
  );

  const setProjectId = useCallback(
    (value: string) => {
      dispatch({ type: 'SET_PROJECT_ID', payload: value });
    },
    [dispatch]
  );
  const setTimezone = useCallback(
    (value: string) => {
      dispatch({ type: 'SET_TIMEZONE', payload: value });
    },
    [dispatch]
  );
  const setManagers = useCallback(
    (value: LabelledEntity[]) => {
      dispatch({ type: 'SET_MANAGERS', payload: value });
    },
    [dispatch]
  );
  const setTemplates = useCallback(
    (value: InspectionTemplateModel[]) => {
      dispatch({ type: 'SET_TEMPLATES', payload: value });
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      setManagers,
      setTimezone,
      setProcesses,
      setProjectId,
      setFiltersResponse,
      setSites,
      setTemplates,
    }),
    []
  );
}
