import { Tab, Tabs } from '@mui/material';
import { useIsOffline } from 'components/common/withIsOffline';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { useVisibleFieldsState } from 'components/dataProviders/withVisibleFields';
import { MemoButtonContained } from 'components/general/ButtonContained/ButtonContained';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import React, {
  PropsWithChildren,
  ReactElement,
  memo,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { processesSelector } from 'redux/selectors/processes';
import {
  StaticField,
  getAvailableFields,
} from 'shared/domain/visibleField/availableFields';
import { createAvailableFieldsSet } from 'shared/domain/visibleField/createAvailableFieldsSet';
import {
  ChartFilter,
  ChartFiltersUnion,
  FilterTypes,
} from 'shared/types/analytics';
import useIsMobile from '../../../hooks/useIsMobile';
import { useChartContext } from '../ChartsProvider';
import { ChartContext } from '../types';
import { CheckboxControl } from './checkboxControl';
import { DaterangeControl } from './daterangeControl';
import { MultiselectControl } from './multiselectControl';
import { RadioControl } from './radioControl';
import { SegmentsControl } from './segmentsControl';
import { SelectControl } from './selectControl';
import { useStyles } from './styles';

const m24 = { margin: '24px' };
const tabIndicatorPropsDesktop = {
  style: {
    left: '0',
    width: '4px',
  },
};

const MemoControlsRenderer = memo(ControlsRenderer);

type FiltersRendererProps = { id: string };

export function FiltersRenderer({
  id,
}: FiltersRendererProps): ReactElement {
  const chartContext = useChartContext(id);
  const filters = chartContext?.filters;
  const filterTypes = filters && Object.keys(filters);

  if (!chartContext || !filterTypes || !filters) {
    return <></>;
  }

  return <MemoControlsRenderer chartContext={chartContext} />;
}

type ControlsRendererProps = {
  chartContext: ChartContext<ChartFiltersUnion>;
};

function ControlsRenderer({
  chartContext,
}: ControlsRendererProps): ReactElement {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const createDialog = useDialog();
  const isOffline = useIsOffline();
  const processes = useSelector(processesSelector);
  const [equalToOrigin, setEqualToOrigin] = useState(true);

  // if we loaded it means we have the filters, the parent checked this.
  // we should move filters to props instead of taking them manually.
  const filters: ChartFiltersUnion = chartContext.filters!;
  const filtersDisabledOffline = chartContext.filtersDisabledOffline;
  const filterTypes = Object.keys(filters) as (keyof ChartFiltersUnion)[];

  const getInitialValues = useCallback(
    () =>
      filterTypes.map((filterType) =>
        filters[filterType].filters.map(
          (filter: ChartFilter<unknown>) => filter.value
        )
      ),
    [filterTypes, filters]
  );

  const initialValues = getInitialValues();
  const [filtersValues, setFiltersValues] =
    useState<unknown[][]>(initialValues);

  const visibleFields = useVisibleFieldsState();
  const availableFieldsSet = useMemo(() => {
    return createAvailableFieldsSet(
      visibleFields,
      getAvailableFields().filter(
        (field) => !field.canBeDisabled
      ) as StaticField[],
      processes
    );
  }, [visibleFields, processes]);

  const [tabIndex, setTabIndex] = useState(0);
  const [dirty, setDirty] = useState(false);

  const handleTabChange = useCallback(
    (_: React.ChangeEvent<{}>, newValue: number): void => {
      if (!dirty) {
        setTabIndex(newValue);
      } else {
        createDialog({
          title: <FormattedMessage id='discard_filters' />,
          description: (
            <span>
              <FormattedMessage id='discard_filters_description' />
            </span>
          ),
          customControllerLabels: ['general_cancel', 'general_discard'],
        }).then(() => {
          setFiltersValues(getInitialValues());
          setTabIndex(newValue);
          setDirty(false);
        });
      }
    },
    [dirty, getInitialValues, createDialog]
  );

  const createLocalSetter = useCallback((panelIndex, filterIndex) => {
    return (value: unknown): void => {
      setFiltersValues((prev) => {
        const next = [...prev];
        next[panelIndex][filterIndex] = value;
        return next;
      });
      setDirty(true);
    };
  }, []);

  const onApply = useCallback((): void => {
    filterTypes.forEach((filterType, panelIndex) => {
      filters[filterType].filters.forEach(
        (filter: ChartFilter<unknown>, filterIndex: number) => {
          const newValue = filtersValues[panelIndex][filterIndex];
          if (newValue !== undefined) {
            filter.setter(newValue);
          }
        }
      );
    });
    setDirty(false);
    setEqualToOrigin(false);
  }, [filterTypes, filters, filtersValues]);

  useEffect(() => {
    if (equalToOrigin) return;
    const filters = getInitialValues();
    setFiltersValues(filters);
    setEqualToOrigin(true);
  }, [equalToOrigin, getInitialValues]);

  const filtersDisabled = Boolean(filtersDisabledOffline && isOffline);

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        variant='fullWidth'
        value={tabIndex}
        onChange={handleTabChange}
        aria-label='filters-tab'
        orientation={isMobile ? 'horizontal' : 'vertical'}
        indicatorColor='primary'
        TabIndicatorProps={
          !isMobile ? tabIndicatorPropsDesktop : undefined
        }
      >
        {filterTypes.map((filter, panelIndex) => {
          return (
            <Tab
              key={filters[filter].labelId}
              className='tab'
              label={intl.formatMessage({
                id: filters[filter].labelId,
              })}
              {...tabWithFiltersProps(panelIndex)}
            />
          );
        })}
      </Tabs>
      <ForbiddenTooltip
        visibleWhen={filtersDisabled}
        reason='general_offline_operation_unavailable'
      >
        <div className={classes.panelWrapper}>
          {filterTypes.map((filter, panelIndex) => (
            <TabPanel key={filter} value={tabIndex} index={panelIndex}>
              {!!filters[filter].filters.length &&
                filters[filter].filters.map(
                  (control: ChartFilter<unknown>, filterIndex: number) => {
                    if (
                      control.fieldRelation &&
                      !availableFieldsSet.has(control.fieldRelation)
                    ) {
                      return null;
                    }
                    return (
                      <ControlElement
                        key={control.labelId + control.type}
                        type={control.type}
                        filter={control}
                        localSetter={createLocalSetter(
                          panelIndex,
                          filterIndex
                        )}
                        localValue={filtersValues[panelIndex][filterIndex]}
                        disabled={filtersDisabled}
                      />
                    );
                  }
                )}
              {!filters[filter].filters.length && (
                <p style={m24}>{intl.formatMessage({ id: 'none' })}</p>
              )}
            </TabPanel>
          ))}
          <div className={classes.actionsContainer}>
            <MemoButtonContained
              onClick={onApply}
              disabled={filtersDisabled}
            >
              <FormattedMessage id='general_apply' />
            </MemoButtonContained>
          </div>
        </div>
      </ForbiddenTooltip>
    </div>
  );
}

function TabPanel(
  props: PropsWithChildren<{ value: number; index: number }>
): ReactElement {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const { children, value, index, ...other } = props;

  return (
    <div
      className={classes.grid}
      role='tabpanel'
      hidden={value !== index}
      id={`chart-filters-tabpanel-${index}`}
      aria-labelledby={`chart-filters-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function tabWithFiltersProps(index: number): {
  id: string;
  'aria-controls': string;
} {
  return {
    id: `chart-filters-tab-${index}`,
    'aria-controls': `chart-filters-tabpanel-${index}`,
  };
}

const ControlElementTypes = {
  [FilterTypes.radio]: RadioControl,
  [FilterTypes.checkbox]: CheckboxControl,
  [FilterTypes.multiselect]: MultiselectControl,
  [FilterTypes.daterange]: DaterangeControl,
  [FilterTypes.select]: SelectControl,
  [FilterTypes.segments]: SegmentsControl,
};

function ControlElement(props: {
  type: FilterTypes;
  filter: ChartFilter<unknown>;
  localSetter: (value: any) => void;
  localValue: any;
  disabled: boolean;
}): ReactElement {
  const ControlComponent = ControlElementTypes[props.type];
  return (
    <ControlComponent
      filter={props.filter}
      localSetter={props.localSetter}
      localValue={props.localValue}
      disabled={props.disabled}
    />
  );
}
