import { FailedInspectionFactorFilters } from 'shared/types/analytics';
import { setMultiselectFilterValue } from 'charts/shared/filters';
import { FilterAction } from '../types';

export function filtersReducer(
  state: FailedInspectionFactorFilters | undefined,
  action: FilterAction
): FailedInspectionFactorFilters | undefined {
  if (action.type === 'REWRITE_FILTERS') return action.payload;
  if (!state) return undefined;

  switch (action.type) {
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dataScope: {
          ...state.dataScope,
          filters: state.dataScope.filters.map((filter, index) =>
            index === 0 ? { ...filter, value: action.payload } : filter
          ),
        },
      };

    case 'SET_PROCESSES':
      return setMultiselectFilterValue(state, 1, action.payload);

    case 'SET_SITES':
      return setMultiselectFilterValue(state, 2, action.payload);

    case 'SET_MANAGERS':
      return setMultiselectFilterValue(state, 3, action.payload);

    default: {
      // eslint-disable-next-line no-console
      console.error('Unknown ACTION in filtersReducer');
      return state;
    }
  }
}
