import { useEffect, useReducer } from 'react';
import { useDependenciesActions } from './dependenciesActions';
import { LabelledEntity } from 'shared/types/commonView';
import {
  AverageInspectionResultDependencies,
  IsDependenciesDefined,
} from '../types';
import { ProcessType } from 'shared/types/form';
import { SiteModel } from 'shared/domain/site/types/model';
import {
  AverageInspectionResultFilters,
  FilterValues,
} from 'shared/types/analytics';
import { DefinedDependencies, Dependencies } from 'charts/shared/types';
import { dependenciesReducer } from '../reducers/dependenciesReducer';

export function useDependencies(
  managers: LabelledEntity[],
  userProcesses: ProcessType[],
  sites: SiteModel[],
  projectId: string,
  timezone: string,
  filtersResponse: FilterValues | undefined
): {
  dependencies: Dependencies<
    AverageInspectionResultDependencies,
    AverageInspectionResultFilters
  >;
  isDependenciesDefined: IsDependenciesDefined;
} {
  const [dependencies, dispatchDependencies] = useReducer(
    dependenciesReducer,
    {}
  );

  const {
    setManagers,
    setFiltersResponse,
    setProcesses,
    setProjectId,
    setSites,
    setTimezone,
  } = useDependenciesActions(dispatchDependencies);

  useEffect(() => {
    setManagers(managers);
  }, [managers]);
  useEffect(() => {
    setProcesses(userProcesses);
  }, [userProcesses]);
  useEffect(() => {
    setSites(sites);
  }, [sites]);
  useEffect(() => {
    setProjectId(projectId);
  }, [projectId]);
  useEffect(() => {
    setTimezone(timezone);
  }, [timezone]);
  useEffect(() => {
    if (!filtersResponse) return;
    setFiltersResponse(filtersResponse);
  }, [filtersResponse]);

  return {
    dependencies,
    isDependenciesDefined,
  };
}

function isDependenciesDefined(
  dependencies: Dependencies<
    AverageInspectionResultDependencies,
    AverageInspectionResultFilters
  >
): dependencies is DefinedDependencies<
  AverageInspectionResultDependencies,
  AverageInspectionResultFilters
> {
  return (
    !!dependencies.filters &&
    !!dependencies.managers &&
    !!dependencies.processes &&
    !!dependencies.sites &&
    !!dependencies.projectId &&
    !!dependencies.timezone
  );
}
