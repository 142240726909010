import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import {
  ApexSeriesAndLabels,
  CompletedInspectionsFilters,
  DateRangeObject,
} from 'shared/types/analytics';
import {
  filterByCreatedBy,
  filterByDateRange,
  filterByProcess,
  filterInspectionBySite,
} from '../seriesCreation';

export async function createSeriesAndLabelsFromInspectionsWithFilters(
  inspections: InspectionEntity[],
  templateProcessByIdRecord: Record<string, { process: string }>,
  filters: CompletedInspectionsFilters,
  timezone: string
): Promise<ApexSeriesAndLabels> {
  const [dateRangeFilter, processFilter, siteFilter, createdByFilter] =
    filters.dataScope.filters;

  const filteredInspections = inspections.filter((inspection) => {
    const passedDateRange = filterByDateRange(
      dateRangeFilter.value as DateRangeObject,
      inspection.inspectionDate || inspection.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return false;
    }

    if (!templateProcessByIdRecord[inspection.template]) {
      throw new Error(
        `Template _id:${inspection.template}; missing for inspection _id:${inspection._id}`
      );
    }
    const passedProcess = filterByProcess(
      processFilter.value as string[],
      templateProcessByIdRecord[inspection.template]
    );
    if (!passedProcess) {
      return false;
    }

    const passedSite = filterInspectionBySite(
      siteFilter.value as string[],
      inspection
    );
    if (!passedSite) {
      return false;
    }

    const passedInspector = filterByCreatedBy(
      createdByFilter.value as string[],
      inspection
    );
    if (!passedInspector) {
      return false;
    }

    return inspection.isCompleted;
  });

  return [[filteredInspections.length], ['']];
}
