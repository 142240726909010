import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import {
  ApexSeriesAndLabels,
  AverageInspectionResultFilters,
  DateRangeObject,
} from 'shared/types/analytics';
import {
  filterByCreatedBy,
  filterByDateRange,
  filterByProcess,
  filterInspectionBySite,
} from '../seriesCreation';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';

/**
 * Calculates inspection metrics based on compliance check results
 *
 * Score Formula: [((T - N) - F) / (T - N)] * 100%
 *
 * Where:
 * T = Total items checked during inspection (P + N + F)
 * N = Not applicable items
 * F = Failed/non-compliant items
 * P = Passed items
 *
 * The formulas exclude not applicable items (N) from calculations to ensure
 * the metrics reflect only items that were actually checked.
 *
 * Example:
 * If inspection has:
 * - 100 total items (T)
 * - 20 not applicable (N)
 * - 10 failed (F)
 *
 * Then:
 * Score = [((100 - 20) - 10) / (100 - 20)] * 100% = 87.5%
 *
 * @returns calculated score in ApexSeriesAndLabels format
 */

export async function createSeriesAndLabelsFromInspectionsWithFilters(
  inspections: InspectionEntity[],
  templateProcessByIdRecord: Record<string, { process: string }>,
  filters: AverageInspectionResultFilters,
  timezone: string
): Promise<ApexSeriesAndLabels> {
  const [dateRangeFilter, processFilter, siteFilter, createdByFilter] =
    filters.dataScope.filters;

  const seriesObj = {
    [ComplianceCheckResult.passed]: 0,
    [ComplianceCheckResult.notPassed]: 0,
    [ComplianceCheckResult.notApplicable]: 0,
  };

  inspections.forEach((inspection) => {
    const passedDateRange = filterByDateRange(
      dateRangeFilter.value as DateRangeObject,
      inspection.inspectionDate || inspection.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return;
    }

    if (!templateProcessByIdRecord[inspection.template]) {
      throw new Error(
        `Template _id:${inspection.template}; missing for inspection _id:${inspection._id}`
      );
    }
    const passedProcess = filterByProcess(
      processFilter.value as string[],
      templateProcessByIdRecord[inspection.template]
    );
    if (!passedProcess) {
      return;
    }

    const passedSite = filterInspectionBySite(
      siteFilter.value as string[],
      inspection
    );
    if (!passedSite) {
      return;
    }

    const passedInspector = filterByCreatedBy(
      createdByFilter.value as string[],
      inspection
    );
    if (!passedInspector) {
      return;
    }

    inspection.protocol.forEach((protocolElement) => {
      if (
        protocolElement.complianceCheck.result ===
        ComplianceCheckResult.unset
      ) {
        return;
      }
      seriesObj[protocolElement.complianceCheck.result] += 1;
    });
  });

  const passed = seriesObj[ComplianceCheckResult.passed];
  const failed = seriesObj[ComplianceCheckResult.notPassed];

  const result = (passed / (passed + failed || 1)) * 100;

  return [[Math.round((result + Number.EPSILON) * 100) / 100], ['%']];
}
