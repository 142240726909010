import { CompletedInspectionsFilters } from 'shared/types/analytics';
import { FilterAction, FilterActionCalls } from '../types';
import { Dispatch, useCallback, useMemo } from 'react';

export function useFiltersActions(
  dispatch: Dispatch<FilterAction>
): FilterActionCalls {
  const setDateRange = useCallback(
    (value: { from: string; to: string }) => {
      dispatch({ type: 'SET_DATE_RANGE', payload: value });
    },
    [dispatch]
  );

  const setProcesses = useCallback(
    (value: string[]) => {
      dispatch({ type: 'SET_PROCESSES', payload: value });
    },
    [dispatch]
  );

  const setSites = useCallback(
    (value: string[]) => {
      dispatch({ type: 'SET_SITES', payload: value });
    },
    [dispatch]
  );

  const setManagers = useCallback(
    (value: string[]) => {
      dispatch({ type: 'SET_MANAGERS', payload: value });
    },
    [dispatch]
  );

  const rewriteFilters = useCallback(
    (value: CompletedInspectionsFilters) => {
      dispatch({ type: 'REWRITE_FILTERS', payload: value });
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      setDateRange,
      setProcesses,
      setSites,
      setManagers,
      rewriteFilters,
    }),
    []
  );
}
