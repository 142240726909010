import { Dependencies } from 'charts/shared/types';
import {
  CompletedInspectionsByTemplateDependencies,
  InitializeDependencyAction,
} from '../types';
import { CompletedInspectionsByTemplateFilters } from 'shared/types/analytics';

export function dependenciesReducer(
  state: Dependencies<
    CompletedInspectionsByTemplateDependencies,
    CompletedInspectionsByTemplateFilters
  >,
  action: InitializeDependencyAction
): Dependencies<
  CompletedInspectionsByTemplateDependencies,
  CompletedInspectionsByTemplateFilters
> {
  switch (action.type) {
    case 'SET_FILTERS_RESPONSE':
      return {
        ...state,
        filters: action.payload,
      };

    case 'SET_PROCESSES':
      return {
        ...state,
        processes: action.payload,
      };

    case 'SET_SITES':
      return {
        ...state,
        sites: action.payload,
      };

    case 'SET_MANAGERS':
      return {
        ...state,
        managers: action.payload,
      };

    case 'SET_PROJECT_ID':
      return {
        ...state,
        projectId: action.payload,
      };

    case 'SET_TIMEZONE':
      return {
        ...state,
        timezone: action.payload,
      };
    case 'SET_TEMPLATES':
      return {
        ...state,
        templates: action.payload,
      };

    default:
      const exhaustiveCheck: never = action;
      throw new Error(
        `Unhandled dependencies reducer case: ${exhaustiveCheck['type']}`
      );
  }
}
