import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { ConfigData } from 'serviceWorker/db/config';
import { ChartDataEntity } from 'serviceWorker/db/analytics';
import { HashMap } from 'shared/types/commonView';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { getFetchConfig } from '../config';
import {
  CalculationFunction,
  IssueChart,
  CostsUnion,
  EstimatedCost,
  EstimatedSingleCost,
  FinalCost,
  FinalSingleCost,
  Finances,
  InspectionChart,
} from 'shared/types/analytics';

const CHART_CACHE_TIME = 1000 * 60 * 60 * 3; // 3h

export async function getConfig(): Promise<ConfigData> {
  const configData = await getFetchConfig();

  if (!configData || !configData.projectId) {
    return configFromEvent();
  }

  return configData;
}

export function configFromEvent(): Promise<ConfigData> {
  return new Promise((resolve, reject) => {
    const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
    broadcast.onmessage = (event: Message): void => {
      if (event.type === DomainMessagesTypes.configSet) {
        broadcast.close();
        resolve(event.data);
      }
    };
  });
}

export function createChartDataEntity(
  series: any[],
  filters: any,
  name: IssueChart | InspectionChart,
  projectId: string,
  locale: string,
  additionalParams: any,
  updatedAt: number
): ChartDataEntity {
  return {
    _id: `${projectId}_${name}`,
    series,
    filters,
    name,
    projectId,
    locale,
    additionalParams,
    updatedAt,
  };
}

export function areObjectsEqual(curr: any, prev: any): boolean {
  const result = JSON.stringify(curr) === JSON.stringify(prev);
  return result;
}

export function calculateFinances(issues: IssueEntity[]): Finances {
  const finances = issues.reduce<Finances>(
    (result: Finances, issue: IssueEntity): Finances => {
      const newCosts: Finances = {
        estimatedCosts: calculateCost(
          issue.extendedData.estimatedCost,
          updateCost
        ),
        finalCosts: calculateCost(
          issue.extendedData.finalCost,
          updateCostObject
        ),
        expectedFines: calculateFine(
          issue.primaryData.subcontractors || [],
          issue.extendedData.expectedFine,
          updateFine
        ),
        imposedFines: calculateFine(
          issue.primaryData.subcontractors || [],
          issue.extendedData.imposedFine,
          updateFine
        ),
      };

      updateAllCosts(result, newCosts);
      return result;
    },
    {
      estimatedCosts: {},
      finalCosts: {},
      expectedFines: {},
      imposedFines: {},
    }
  );
  return finances;
}

function calculateCost<T>(
  issueCosts: CostsUnion[] | undefined,
  calculationFunction: CalculationFunction<T>
): HashMap<T> {
  const resultCosts: HashMap<T> = {};
  if (Array.isArray(issueCosts)) {
    issueCosts.forEach((cost) => {
      if (!cost.coveredBy) {
        return;
      }
      resultCosts[cost.coveredBy] = calculationFunction(
        resultCosts[cost.coveredBy],
        cost
      );
    });
  }

  return resultCosts;
}

function updateAllCosts(currentCosts: Finances, newCosts: Finances): void {
  updateCosts(
    'estimatedCosts',
    currentCosts,
    newCosts.estimatedCosts,
    updateCost
  );
  updateCosts(
    'finalCosts',
    currentCosts,
    newCosts.finalCosts,
    updateCostObject
  );
  updateCosts(
    'expectedFines',
    currentCosts,
    newCosts.expectedFines,
    updateFine
  );
  updateCosts(
    'imposedFines',
    currentCosts,
    newCosts.imposedFines,
    updateFine
  );
}

function updateCosts(
  costKey: keyof Finances,
  currentCosts: Finances,
  newCosts: Finances[keyof Finances],
  // im unable to type it properly
  calculationFunction: CalculationFunction<any>
): void {
  Object.entries(newCosts).forEach((entry: [string, any]) => {
    const [company, cost] = entry;
    currentCosts[costKey][company] = calculationFunction(
      currentCosts[costKey][company],
      cost
    );
  });
}

function updateCostObject(
  maybeCost: FinalCost | undefined,
  cost: FinalSingleCost
): FinalCost {
  if (!maybeCost) {
    return {
      outstanding: parseFloat(cost.outstanding),
      settled: parseFloat(cost.settled),
    };
  } else {
    return {
      outstanding: maybeCost.outstanding + parseFloat(cost.outstanding),
      settled: maybeCost.settled + parseFloat(cost.settled),
    };
  }
}

function updateCost(
  maybeCost: EstimatedCost | undefined,
  costObject: EstimatedSingleCost
): EstimatedCost {
  if (!maybeCost) {
    return {
      cost: parseFloat(costObject.cost),
    };
  } else {
    return {
      cost: maybeCost.cost + parseFloat(costObject.cost),
    };
  }
}

function calculateFine(
  subcontractors: string[],
  issueFine: number | undefined,
  calculationFunction: CalculationFunction<number>
): HashMap<number> {
  const resultCosts: HashMap<number> = {};
  if (Array.isArray(subcontractors) && issueFine) {
    subcontractors.forEach((subcontractor) => {
      resultCosts[subcontractor] = calculationFunction(
        resultCosts[subcontractor],
        issueFine
      );
    });
  }

  return resultCosts;
}

function updateFine(maybeFine: number | undefined, fine: number): number {
  if (maybeFine === undefined) {
    return fine;
  } else {
    return maybeFine + fine;
  }
}

export function canUseCache(cachedAt: number): boolean {
  return Date.now() - cachedAt < CHART_CACHE_TIME;
}

export function canReuseLastSavedChart(
  recievedChart: ChartDataEntity | undefined,
  forceUpdate: boolean | undefined,
  locale: string,
  filtersFromClient: any,
  additionalParams: any
): recievedChart is ChartDataEntity {
  return Boolean(
    recievedChart &&
      !forceUpdate &&
      locale === recievedChart.locale &&
      canUseCache(recievedChart.updatedAt) &&
      equalFilters(filtersFromClient, recievedChart.filters) &&
      equalParams(additionalParams, recievedChart.additionalParams)
  );
}

function equalFilters(
  filtersRecievedFromClient: any,
  currentSeriesFilters: any
): boolean {
  if (!filtersRecievedFromClient) {
    return Boolean(currentSeriesFilters);
  }
  if (!currentSeriesFilters) {
    return false;
  }

  return areObjectsEqual(filtersRecievedFromClient, currentSeriesFilters);
}

function equalParams(
  paramsRecievedFromClient: any,
  currentSeriesParams: any
): boolean {
  if (!paramsRecievedFromClient && !currentSeriesParams) {
    return true;
  }

  return areObjectsEqual(paramsRecievedFromClient, currentSeriesParams);
}
