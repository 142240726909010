import {
  DateRangeIntervalWithoutWeek,
  InspectionEvolutionFilters,
} from 'shared/types/analytics';
import { FilterAction, FilterActionCalls } from '../types';
import { Dispatch, useCallback, useMemo } from 'react';
import { showToaster } from 'redux/actions/toasterActions';
import { TOASTER_TYPES } from 'shared/enums';
import { useDispatch } from 'react-redux';

export function useFiltersActions(
  dispatch: Dispatch<FilterAction>,
  timezone: string
): FilterActionCalls {
  const reduxDispatch = useDispatch();
  const showTooLargeIntervalErrorToast = useCallback(() => {
    reduxDispatch(
      showToaster({
        type: TOASTER_TYPES.FAILURE,
        message: { id: 'date_range_interval_too_large_error' },
        toasterPosition: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        hideDelay: 6000,
      })
    );
  }, [reduxDispatch]);

  const setDateRange = useCallback(
    (value: { from: string; to: string }) => {
      if (!timezone) return;
      dispatch({
        type: 'SET_DATE_RANGE',
        payload: {
          ...value,
          timezone: timezone,
          showErrorDialog: showTooLargeIntervalErrorToast,
        },
      });
    },
    [dispatch, showTooLargeIntervalErrorToast, timezone]
  );

  const setProcesses = useCallback(
    (value: string[]) => {
      dispatch({ type: 'SET_PROCESSES', payload: value });
    },
    [dispatch]
  );

  const setSites = useCallback(
    (value: string[]) => {
      dispatch({ type: 'SET_SITES', payload: value });
    },
    [dispatch]
  );

  const setManagers = useCallback(
    (value: string[]) => {
      dispatch({ type: 'SET_MANAGERS', payload: value });
    },
    [dispatch]
  );

  const setDateInterval = useCallback(
    (value: DateRangeIntervalWithoutWeek) => {
      if (!timezone) return;
      dispatch({
        type: 'SET_DATE_INTERVAL',
        payload: {
          interval: value,
          timezone: timezone,
          showErrorDialog: showTooLargeIntervalErrorToast,
        },
      });
    },
    [dispatch, timezone]
  );

  const rewriteFilters = useCallback(
    (value: InspectionEvolutionFilters) => {
      dispatch({ type: 'REWRITE_FILTERS', payload: value });
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      setDateRange,
      setProcesses,
      setSites,
      setManagers,
      setDateInterval,
      rewriteFilters,
    }),
    [dispatch, showTooLargeIntervalErrorToast, timezone]
  );
}
